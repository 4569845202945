'use client'

import { useState } from 'react'
import { Icon } from '@vinted/web-ui'
import { ElectronicsBadgeMultichrome32 } from '@vinted/multichrome-icons'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import { EscrowFeeElectronicsVerificationModel } from 'types/models/escrow-fees'
import ElectronicsVerification from 'components/ElectronicsVerification'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from '_libs/common/event-tracker/events'

import FeeDetails from '../FeeDetails'

type Props = {
  electronicsVerificationFee: EscrowFeeElectronicsVerificationModel
  isItemOwner: boolean
  itemId: number
}

const ElectronicsVerificationFee = ({ electronicsVerificationFee, isItemOwner, itemId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const translate = useTranslate('item_price_breakdown_detailed')
  const { track } = useTracking()

  if (!electronicsVerificationFee) return null

  const handelInfoIconClick = () => {
    setIsModalOpen(true)

    track(
      clickEvent({
        screen: Screen.PriceBreakdownModal,
        target: isItemOwner
          ? ClickableElement.ElectronicsVerificationSeller
          : ClickableElement.ElectronicsVerificationBuyer,
        targetDetails: JSON.stringify({
          item_id: itemId,
        }),
      }),
    )
  }

  const renderInfoIcon = () => (
    <>
      <button
        className="u-flexbox u-align-items-center u-cursor-pointer"
        onClick={handelInfoIconClick}
        type="button"
      >
        <Icon
          color={Icon.Color.GreyscaleLevel4}
          name={InfoCircle16}
          testId="electronics-verification-info-icon"
          aria={{
            'aria-label': translate('actions.a11y.electronics_verification_info'),
          }}
        />
      </button>
      <ElectronicsVerification
        show={isModalOpen}
        verificationFee={electronicsVerificationFee.finalPrice}
        onClose={() => setIsModalOpen(false)}
        isViewingOwnItem={isItemOwner}
        screen={Screen.PriceBreakdownModal}
      />
    </>
  )

  return (
    <FeeDetails
      testId="item-price-breakdown-electronics-verification-cell"
      feeTitle={translate('electronics_verification')}
      escrowFee={electronicsVerificationFee}
      prefix={
        <Icon
          name={ElectronicsBadgeMultichrome32}
          aria={{
            'aria-hidden': 'true',
          }}
        />
      }
      infoIcon={renderInfoIcon()}
      isItemOwner={isItemOwner}
      noteText={
        isItemOwner ? translate('electronics_verification_seller_info') : translate('optional')
      }
    />
  )
}

export default ElectronicsVerificationFee
