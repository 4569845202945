'use client'

import { KeyboardEvent } from 'react'
import { Icon, Text, Spacer } from '@vinted/web-ui'
import { Shield12 } from '@vinted/monochrome-icons'
import { useIntl } from 'react-intl'

import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { CurrencyAmountModel } from 'types/models'

import ServiceTitle from '../ServiceTitle'
import SellerText from '../SellerText'

type Props = {
  isItemOwner?: boolean
  isSellerBusiness: boolean
  totalItemPrice: CurrencyAmountModel
  shouldTrimTitle?: boolean
  shouldMinimiseTitle?: boolean
  onClick: () => void
  onKeyDown: (event: KeyboardEvent) => void
}

const NonInlinePrice = ({
  totalItemPrice,
  isItemOwner,
  isSellerBusiness,
  shouldTrimTitle,
  shouldMinimiseTitle,
  onClick,
  onKeyDown,
}: Props) => {
  const translate = useTranslate('item.includes_buyer_protection')
  const { locale } = useIntl()
  const isLongTextBehaviorFixOn =
    useAbTest({
      abTestName: 'dd_tx_long_text_behavior_web',
    })?.variant === 'on'

  const formattedTotalPrice = formatCurrencyAmount(totalItemPrice, locale)

  if (isLongTextBehaviorFixOn) {
    return (
      <button
        tabIndex={0}
        aria-label={translate('title', { price: formattedTotalPrice })}
        onKeyDown={onKeyDown}
        onClick={onClick}
        type="button"
        className="u-text-left"
      >
        <Text
          as="div"
          type={Text.Type.Title}
          text={formattedTotalPrice}
          clickable
          underline={false}
          tabIndex={-1}
        />
        <div>
          <span className="service-fee-included-title--text">
            <ServiceTitle
              isItemOwner={isItemOwner}
              isSellerBusiness={isSellerBusiness}
              shouldMinimiseTitle={shouldMinimiseTitle}
              shouldTrimTitle={shouldTrimTitle}
              inline
            />
          </span>
          <span className="service-fee-included-title--icon">
            <Icon color={Icon.Color.Primary} name={Shield12} testId="service-fee-included-icon" />
          </span>
          {isItemOwner && <SellerText shouldMinimiseTitle={shouldMinimiseTitle} />}
        </div>
      </button>
    )
  }

  return (
    <div
      tabIndex={0}
      aria-label={translate('title', { price: formattedTotalPrice })}
      onKeyDown={onKeyDown}
      onClick={onClick}
      role="button"
      className="u-flexbox u-align-items-flex-start u-flex-direction-column"
    >
      <Text
        as="div"
        type={Text.Type.Title}
        text={formattedTotalPrice}
        clickable
        underline={false}
        tabIndex={-1}
      />
      <div className="u-flexbox u-align-items-center u-flex-wrap">
        <ServiceTitle
          isItemOwner={isItemOwner}
          isSellerBusiness={isSellerBusiness}
          shouldMinimiseTitle={shouldMinimiseTitle}
          shouldTrimTitle={shouldTrimTitle}
        />
        <Spacer size={Spacer.Size.XSmall} orientation={Spacer.Orientation.Vertical} />
        <Icon color={Icon.Color.Primary} name={Shield12} testId="service-fee-included-icon" />
        {isItemOwner && <SellerText shouldMinimiseTitle={shouldMinimiseTitle} />}
      </div>
    </div>
  )
}

export default NonInlinePrice
